import Vue from 'vue'

export const logger = {
  error: (error, errorName) => {
    sendError(error, errorName)
  },
  errorFromJson: (error) => {
    try {
      error.response.json().then((errorParsed) => {
        const errorToSend = errorParsed && errorParsed.error
        this.errorFromString(errorToSend)
      })
    } catch {
      sendError(error)
    }
  },
  errorFromString: (error) => {
    const errorToSend = new Error(error)
    sendError(errorToSend)
  }
}

function sendError(error, errorName) {
  if (process.env.ROLLBAR_TOKEN) {
    if (error instanceof Error || error instanceof String) {
      Vue.rollbar.error(error)
    } else {
      const name = errorName || error?.message || error?.msg
      Vue.rollbar.error(name, error)
    }
  } else {
    console.error(error)
  }
}
