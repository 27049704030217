import store from 'src/store'
import { getClientSession, setClientSession } from 'utilities/clientSessionCookie'
import { getSessionsTokenURL } from 'api/url'
import API from 'api/fetch'

import { asyncComponentLoader } from './helpers'
import paths from './paths'

export const OTA_ROUTES = [
  {
    path: '/',
    component: asyncComponentLoader('OtaView', 'OtaRouterView'),
    children: [
      {
        path: '',
        name: paths.ota.home,
        component: asyncComponentLoader('OtaView', 'OtaHome'),
        meta: {
          pageTitle: 'otaHome',
          noHotelCode: true
        }
      },
      {
        path: 'search-hotels',
        name: paths.ota.searchHotels,
        component: asyncComponentLoader('OtaView', 'OtaSearchHotels'),
        meta: {
          pageTitle: 'otaSearchHotels',
          noHotelCode: true
        }
      },
      {
        path: 'hotels/:code',
        component: asyncComponentLoader('OtaView', 'OtaFacilityRouterView'),
        children: [
          {
            path: '',
            name: paths.ota.searchRooms,
            component: asyncComponentLoader('OtaView', 'OtaSearchRooms/index'),
            meta: {
              pageTitle: 'otaSearchResults'
            }
          }
        ]
      },
      {
        path: 'confirm-reservation',
        name: paths.ota.confirmReservation,
        meta: {
          pageTitle: 'otaBookingConfirmation',
          noHotelCode: true
        },
        component: asyncComponentLoader('OtaView', 'OtaConfirmReservation')
      }
    ]
  },
  {
    path: '/reservation',
    name: paths.ota.reservation.index,
    component: asyncComponentLoader('OtaView', 'OtaReservation/index'),
    children: [
      {
        path: 'guest-detail',
        name: paths.ota.reservation.guestDetail,
        component: asyncComponentLoader('OtaView', 'OtaReservation/GuestDetail'),
        meta: {
          protected: false,
          pageTitle: 'otaGuestDetail'
        }
      },
      {
        path: 'payment',
        name: paths.ota.reservation.payment,
        component: asyncComponentLoader('OtaView', 'OtaReservation/Payment'),
        meta: {
          protected: false,
          pageTitle: 'otaPayment'
        }
      },
      {
        path: 'confirmation/:confirmationNumber',
        name: paths.ota.reservation.confirmation,
        component: asyncComponentLoader('OtaView', 'OtaReservation/Confirmation'),
        meta: {
          protected: false,
          pageTitle: 'otaConfirmation'
        },
        props: (route) => ({
          confirmationNumber: route.params.confirmationNumber,
          email: route.query.email,
          bypass_token: route.query.bypass_token,
          code: route.code,
          from: route.params.from
        })
      },
      {
        path: 'fail',
        name: paths.ota.reservation.failure,
        component: asyncComponentLoader('OtaView', 'OtaReservation/Failure'),
        meta: {
          protected: false
        },
        props: (route) => ({
          code: route.query.code,
          checkin: route.query.checkin,
          checkout: route.query.checkout,
          adults: route.query.adults,
          errorCode: route.query.error_code,
          error: route.query.error
        })
      }
    ]
  }
]

export const otaRouterGuard = async (to, from, next) => {
  let sessionToken = getClientSession()

  if (!sessionToken) {
    try {
      const res = await API.postData(
        getSessionsTokenURL(),
        {
          key: process.env.IDENTITY_KEY,
          secret: process.env.IDENTITY_SECRET
        },
        'auth'
      )
      sessionToken = res?.data?.client_session
      setClientSession(sessionToken)
    } catch (error) {}
  }

  store.commit('setting/setSessionToken', sessionToken)
  next()
}
