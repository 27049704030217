import { onMounted, getCurrentInstance, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { SUPPORT_MCP_CURRENCIES } from 'constants/currency'
import _get from 'lodash/get'
import _isFinite from 'lodash/isFinite'

const ignoreNavigationDuplicated = (err) => {
  if (err.name !== 'NavigationDuplicated') {
    throw err
  }
}

export function formatFieldNameBaseOnMcp(fieldName, isMcpMode) {
  if (!isMcpMode) return fieldName
  return `mcp_${fieldName}.cents`
}

export const useMcpCommon = () => {
  const { $store } = getCurrentInstance().proxy
  const isMcpMode = computed(() => $store.getters['mcp/isMcpMode'])

  const formatField = (fieldName) => formatFieldNameBaseOnMcp(fieldName, isMcpMode.value)

  /**
   *
   * @param {object} obj
   * @param {string} fieldName any field name that needs to be formated for MCP condition
   * @param {number} defaultValue (optional)
   * @returns number
   */
  const getMcpPriceValue = (obj, fieldName, defaultValue) =>
    _get(obj, formatField(fieldName), defaultValue)

  return {
    formatField,
    getMcpPriceValue
  }
}

export const useMcpInit = () => {
  const { $store } = getCurrentInstance().proxy
  const route = useRoute()
  const router = useRouter()

  onMounted(() => {
    initCurrency()
  })

  function initCurrency() {
    const currency = route.query.mcp_currency || $store.getters['mcp/selectedCurrency']
    const isMcpEnabled = $store.getters['mcp/isMcpEnabled']
    const isUpdateMode = route.query.current_reservation
    const isValid = checkIsValidCurrency(currency)

    if (!isValid || !isMcpEnabled || isUpdateMode) {
      const { mcp_currency, ...rest } = route.query
      router.replace({ query: rest }).catch(ignoreNavigationDuplicated)
      return
    }

    $store.dispatch('mcp/setSelectedCurrency', currency)
    router
      .replace({ query: { ...route.query, mcp_currency: currency } })
      .catch(ignoreNavigationDuplicated)
  }

  function checkIsValidCurrency(currency) {
    const validCurrencies = new Set(Object.keys(SUPPORT_MCP_CURRENCIES))
    return validCurrencies.has(currency)
  }
}

export const useMcpPrice = (bookingDetails) => {
  const { getMcpPriceValue } = useMcpCommon()

  const accommodationTax = computed(() =>
    getMcpPriceValue(bookingDetails.value, 'accommodation_tax')
  )
  const brrDiscountAmount = computed(() =>
    getMcpPriceValue(bookingDetails.value, 'brr_discount_amount')
  )
  const couponDiscountAmount = computed(() =>
    getMcpPriceValue(bookingDetails.value, 'coupon_discount_amount')
  )
  const depositAmount = computed(() => getMcpPriceValue(bookingDetails.value, 'deposit_amount'))
  const discountedPrice = computed(() => getMcpPriceValue(bookingDetails.value, 'discounted_price'))
  const extraPriceWithoutTax = computed(() =>
    getMcpPriceValue(bookingDetails.value, 'extra_price_without_tax')
  )
  const originalPrice = computed(() => getMcpPriceValue(bookingDetails.value, 'original_price'))
  const originalPriceWithTax = computed(() =>
    getMcpPriceValue(bookingDetails.value, 'original_price_with_tax')
  )
  const promotionDiscountAmount = computed(() =>
    getMcpPriceValue(bookingDetails.value, 'promotion_discount_amount')
  )
  const remainAmount = computed(() => getMcpPriceValue(bookingDetails.value, 'remain_amount', 0))

  const roomPriceWithoutTax = computed(() =>
    getMcpPriceValue(bookingDetails.value, 'room_price_without_tax')
  )
  const totalSpecialDiscount = computed(() =>
    getMcpPriceValue(bookingDetails.value, 'total_special_discount', 0)
  )
  const spendingPointValue = computed(() =>
    getMcpPriceValue(bookingDetails.value, 'spending_point_value')
  )
  const tax = computed(() => getMcpPriceValue(bookingDetails.value, 'tax'))
  const totalExtraPrice = computed(() =>
    getMcpPriceValue(bookingDetails.value, 'total_extra_price')
  )
  const totalPrice = computed(() => getMcpPriceValue(bookingDetails.value, 'total_price'))

  const totalPriceBeforePointDiscount = computed(() =>
    getMcpPriceValue(bookingDetails.value, 'total_price_before_point_discount')
  )
  const totalPriceWithoutTax = computed(() =>
    getMcpPriceValue(bookingDetails.value, 'total_price_without_tax')
  )
  const voucherCouponDiscountAmount = computed(() =>
    getMcpPriceValue(bookingDetails.value, 'voucher_coupon_discount_amount')
  )
  const cancellationFee = computed(() => getMcpPriceValue(bookingDetails.value.cancellation, 'fee'))

  const promotionDiscountRooms = computed(() =>
    bookingDetails.value.rooms
      .filter((room) => room.promotion_discount_amount > 0)
      .map((room) => {
        const promotionDiscountAmount = getMcpPriceValue(room, 'promotion_discount_amount')
        return { ...room, promotionDiscountAmount }
      })
  )

  const paymentPromotionsDiscounts = computed(() =>
    bookingDetails.value.rooms
      .map((room) => {
        const paymentDiscount = room.discounts.find((discount) => {
          return (
            discount.discount_type === 'payment' && getMcpPriceValue(discount, 'discount_amount')
          )
        })

        return paymentDiscount
          ? {
              ...paymentDiscount,
              discountAmount: getMcpPriceValue(paymentDiscount, 'discount_amount')
            }
          : null
      })
      .filter((discount) => discount)
  )

  const roundingDiscountTotal = computed(() =>
    bookingDetails.value.rooms.reduce((a, c) => {
      return a + getMcpPriceValue(c.rounding_discount, 'discount_amount', 0)
    }, 0)
  )

  const couponDiscountRooms = computed(() => {
    return bookingDetails.value.rooms
      .filter((room) => getMcpPriceValue(room, 'coupon_discount_amount'))
      .map((room) => ({
        ...room,
        couponDiscountAmount: getMcpPriceValue(room, 'coupon_discount_amount')
      }))
  })

  const brrDiscountRooms = computed(() => {
    return bookingDetails.value.rooms
      .filter((room) => getMcpPriceValue(room, 'brr_discount_amount'))
      .map((room) => ({
        ...room,
        brrDiscountAmount: getMcpPriceValue(room, 'brr_discount_amount', 0)
      }))
  })

  const creansmaerdDiscountRooms = computed(() => {
    return bookingDetails.value.rooms
      .map((room) => {
        const creansmaerdDiscount = (room.discounts || []).find(
          (discount) => discount.discount_type === 'creansmaerd'
        )

        return creansmaerdDiscount
          ? {
              ...room,
              creansmaerdDiscount: {
                ...creansmaerdDiscount,
                discountAmount: getMcpPriceValue(creansmaerdDiscount, 'discount_amount')
              }
            }
          : null
      })
      .filter((room) => room)
  })

  return {
    spendingPointValue,
    originalPriceWithTax,
    accommodationTax,
    totalPrice,
    totalSpecialDiscount,
    discountedPrice,
    couponDiscountAmount,
    voucherCouponDiscountAmount,
    promotionDiscountRooms,
    paymentPromotionsDiscounts,
    couponDiscountRooms,
    brrDiscountRooms,
    creansmaerdDiscountRooms,
    roundingDiscountTotal,
    depositAmount,
    remainAmount,
    brrDiscountAmount,
    extraPriceWithoutTax,
    originalPrice,
    promotionDiscountAmount,
    roomPriceWithoutTax,
    tax,
    totalExtraPrice,
    totalPriceBeforePointDiscount,
    totalPriceWithoutTax,
    cancellationFee
  }
}

export const useMcpExtra = (originalExtras) => {
  const { getMcpPriceValue } = useMcpCommon()

  const extras = computed(() =>
    originalExtras.value.map((extra) => {
      extra.prices.forEach((dayPrice) => {
        dayPrice.priceWithoutTax = getMcpPriceValue(dayPrice, 'price_without_tax')
        dayPrice.priceWithTax = getMcpPriceValue(dayPrice, 'price')
      })
      return extra
    })
  )

  return {
    extras
  }
}

export const useMcpRoomPrice = (roomData, roomCount = { value: 1 }) => {
  const { getMcpPriceValue } = useMcpCommon()

  const totalPriceWithoutTax = computed(() => getMcpPriceValue(roomData.value, 'total_price'))

  const signInDiscountPrice = computed(() =>
    getMcpPriceValue(roomData.value.sign_in_discount, 'total_price_after_discount')
  )
  const nonSignInDiscountPrice = computed(() =>
    getMcpPriceValue(roomData.value.non_sign_in_discount, 'total_price_after_discount')
  )

  const signInPriceWithoutTax = computed(() => {
    const pricePerRoom = _isFinite(signInDiscountPrice.value)
      ? signInDiscountPrice.value
      : totalPriceWithoutTax.value
    return pricePerRoom
  })
  const nonSignInPriceWithoutTax = computed(() => {
    const pricePerRoom = _isFinite(nonSignInDiscountPrice.value)
      ? nonSignInDiscountPrice.value
      : totalPriceWithoutTax.value
    return pricePerRoom
  })

  const normalTax = computed(() => {
    const tax = getMcpPriceValue(roomData.value, 'tax', 0)
    const accommodationTax = getMcpPriceValue(roomData.value, 'accommodation_tax', 0)

    return tax + accommodationTax
  })

  const signInTax = computed(() => {
    const tax = getMcpPriceValue(roomData.value.sign_in_discount, 'total_price_after_discount_tax')
    const accommodationTax = getMcpPriceValue(
      roomData.value.sign_in_discount,
      'total_accommodation_tax',
      0
    )

    const taxSum = tax + accommodationTax

    return _isFinite(taxSum) ? taxSum : normalTax.value
  })
  const nonSignInTax = computed(() => {
    const tax = getMcpPriceValue(
      roomData.value.non_sign_in_discount,
      'total_price_after_discount_tax'
    )
    const accommodationTax = getMcpPriceValue(
      roomData.value.non_sign_in_discount,
      'total_accommodation_tax',
      0
    )

    const taxSum = tax + accommodationTax

    return _isFinite(taxSum) ? taxSum : normalTax.value
  })

  const signInPriceDetail = computed(() => ({
    priceWithoutTax: signInPriceWithoutTax.value * roomCount.value,
    tax: signInTax.value * roomCount.value,
    total: (signInPriceWithoutTax.value + signInTax.value) * roomCount.value
  }))

  const nonSignInPriceDetail = computed(() => ({
    priceWithoutTax: nonSignInPriceWithoutTax.value * roomCount.value,
    tax: nonSignInTax.value * roomCount.value,
    total: (nonSignInPriceWithoutTax.value + nonSignInTax.value) * roomCount.value
  }))

  const originalPrice = computed(
    () => (totalPriceWithoutTax.value + normalTax.value) * roomCount.value
  )

  return {
    signInPriceDetail,
    nonSignInPriceDetail,
    originalPrice
  }
}

export const useMcpPaymentMethod = () => {
  const { $store } = getCurrentInstance().proxy

  const isMcpMode = computed(() => $store.getters['mcp/isMcpMode'])
  const isCurrencySwitchedTemporarily = computed(() => !!$store.getters['mcp/temporaryCurrency'])

  const isMcpModeOrTempSwitched = computed(
    () => isMcpMode.value || isCurrencySwitchedTemporarily.value
  )

  const acceptedCreditCardType = computed(() =>
    isCurrencySwitchedTemporarily.value ? ['jcb', 'amex', 'diners'] : ['visa', 'master']
  )

  return {
    isMcpMode,
    isCurrencySwitchedTemporarily,
    isMcpModeOrTempSwitched,
    acceptedCreditCardType
  }
}
