import { DISALLOWED_QUERIES } from 'constants/tripla'

export function isSSORedirect() {
  if (window.location.pathname === '/sso/custom/callback') return true

  const url = window.location.toString()
  return url.includes('isLINERedirect=true') || url.includes('isLINERedirect%3Dtrue')
}

export const containsEmail = (raw) => {
  return /((?!@)|(?!%40))(@|%40)[\w\d]+\.[\w\d]+/i.test(raw)
}

export const cleanUpUrl = (raw) => {
  let ret = raw

  DISALLOWED_QUERIES.forEach((name) => {
    // filter unescaped case
    ret = ret.replace(new RegExp(`(\\?|&)${name}=[^&]+(&|$)`, 'ig'), '$1')
    // filter escaped case
    ret = ret.replace(new RegExp(`(%3f|%26)${name}%3d.+?(%26|$)`, 'ig'), '$1')
  })

  // destroy all emails
  ret = ret.replace(/((?!@)|(?!%40))(@|%40)[\w\d]+\.[\w\d]+/, '')

  return ret
}
