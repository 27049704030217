import Vue from 'vue'
import { findDayAfterToday, parseDate, formatDate02 } from 'utilities/date'
import store from 'store'
import _get from 'lodash.get'
import _isNil from 'lodash/isNil'
import _range from 'lodash/range'

export function buildKidsAges(kidsAgesQuery) {
  let kidsAgesValue = []
  try {
    kidsAgesValue = kidsAgesQuery ? JSON.parse(kidsAgesQuery) : []
  } catch (e) {
    kidsAgesValue = []
  }
  return kidsAgesValue
}

export function buildKidsTiers(kidsTiersQuery, initialSetting) {
  if (!_get(initialSetting, 'kids_setting.kids_type', '').includes('tiers')) return null
  let kidsTiersValue
  try {
    kidsTiersValue = kidsTiersQuery ? JSON.parse(kidsTiersQuery) : []
  } catch (e) {
    kidsTiersValue = []
  }
  const result = (initialSetting?.kids_tiers || []).map((tier) => {
    const tierResult = { ...tier }
    kidsTiersValue.forEach((tierValue) => {
      if (tier.code === tierValue.code) {
        tierResult['children'] = tierValue.children ?? tierValue.count
      }
    })
    if (!tierResult.children) {
      tierResult['children'] = 0
    }
    return tierResult
  })

  return result
}

export function buildRooms(
  roomsQuery,
  defaultAdults = store.getters['setting/getDefautSearch'].adults,
  defaultChildren = 0
) {
  const newRoom = { adults: defaultAdults }

  if (store.getters['setting/showSharedBedKids'] || store.getters['setting/isTiersMode']) {
    newRoom.children = defaultChildren
  }
  if (store.getters['setting/isTiersMode']) {
    newRoom.kids_tiers = []
  }

  if (Vue.prototype.$preview && store.getters['setting/isSupportCompanion']) {
    if (store.getters['membership/getAdultCompanion']?.is_active) {
      newRoom.adult_companion = 0
    }
    if (store.getters['membership/getKidCompanion']?.is_active) {
      newRoom.kid_tier_A_companion = 0
    }
  }

  if (!roomsQuery) return [newRoom]
  try {
    // TODO: need to find a way to ensure there's always children count in the returned value
    const roomArray = JSON.parse(roomsQuery)
    if (roomArray.length === 0) {
      return [newRoom]
    }
    return roomArray.map((r) => {
      r.adults = Number(r.adults || defaultAdults)

      if (store.getters['setting/showSharedBedKids'] || store.getters['setting/isTiersMode']) {
        r.children = Number(r.children || 0)
      } else {
        delete r.children
      }
      if (
        store.getters['setting/isSupportCompanion'] &&
        store.getters['membership/getAdultCompanion']?.is_active
      ) {
        r.adult_companion = Number(r.adult_companion || 0)
      } else {
        delete r.adult_companion
      }

      if (
        store.getters['setting/isSupportCompanion'] &&
        store.getters['membership/getKidCompanion']?.is_active
      ) {
        r.kid_tier_A_companion = Number(r.kid_tier_A_companion || 0)
      } else {
        delete r.kid_tier_A_companion
      }

      if (store.getters['setting/isTiersMode']) {
        const supportedCodes = store.getters['setting/getInit'].kids_tiers.map((tier) => tier.code)
        r.kids_tiers = (r.kids_tiers || []).filter((code) => supportedCodes.includes(code))
        r.children = r.kids_tiers.length
      }

      return r
    })
  } catch (e) {
    return [newRoom]
  }
}

export function shortenKidsTiers(kidsTiers) {
  return Array.isArray(kidsTiers)
    ? kidsTiers
        .map((tier) => {
          return { code: tier.code, children: tier.children }
        })
        .filter((tier) => tier.children > 0)
    : []
}

export function transferTierCodeToTier(tierCodes, kidKey = 'count') {
  const tierCodeMap = {}
  tierCodes.forEach((code) => {
    if (tierCodeMap[code]) {
      tierCodeMap[code] = tierCodeMap[code] + 1
    } else {
      tierCodeMap[code] = 1
    }
  })
  return Object.keys(tierCodeMap).map((code) => ({
    code,
    [kidKey]: tierCodeMap[code]
  }))
}

export function transferTierToTierCodes(kidsTiers) {
  const tierCodes = []
  kidsTiers.forEach(({ code, count, children }) => {
    _range(count || children).forEach(() => {
      tierCodes.push(code)
    })
  })
  return tierCodes
}

export function genQueryParamsResultPage(searchForm, isTiersMode) {
  const { code } = store.getters['setting/getSetting']
  // eslint-disable-next-line camelcase
  const {
    checkin,
    checkout,
    type,
    roomTypeCodes,
    hotelPlanCodes,
    filter,
    is_day_use,
    mode,
    promo_code,
    coupon_code,
    voucher_coupon_code,
    utm_source,
    utm_medium,
    utm_campaign,
    campaignid,
    adgroupid,
    keyword,
    gclid,
    yclid,
    source,
    medium,
    email_id,
    search_type,
    nights,
    current_reservation,
    bypass_token,
    user_id,
    order,
    is_including_occupied,
    dynamic_package,
    lottery_event_uuid,
    lottery_access_token
  } = searchForm
  let query = {
    code,
    checkin,
    checkout,
    type: type || 'rooms',
    is_day_use,
    mode
  }
  if (user_id) query.user_id = user_id
  if (filter) query.filter = filter
  if (order) query.order = order
  if (is_including_occupied !== undefined) query.is_including_occupied = is_including_occupied
  if (promo_code) {
    query.promo_code = promo_code
  }
  if (coupon_code) {
    query.coupon_code = coupon_code
  }
  if (voucher_coupon_code) {
    query.voucher_coupon_code = voucher_coupon_code
  }
  if (current_reservation) {
    query.current_reservation = current_reservation
    query.bypass_token = bypass_token
  }
  if (utm_source) query.utm_source = utm_source
  if (utm_medium) query.utm_medium = utm_medium
  if (utm_campaign) query.utm_campaign = utm_campaign
  if (campaignid) query.campaignid = campaignid
  if (adgroupid) query.adgroupid = adgroupid
  if (keyword) query.keyword = keyword
  if (gclid) query.gclid = gclid
  if (yclid) query.yclid = yclid
  if (source) query.source = source
  if (medium) query.medium = medium
  if (email_id) query.email_id = email_id
  if ((roomTypeCodes || []).length > 0) {
    query.room_type_codes = roomTypeCodes.join(',')
  }
  if ((hotelPlanCodes || []).length > 0) {
    query.hotel_plan_codes = hotelPlanCodes.join(',')
  }
  if (dynamic_package === 'wbf') {
    query.dynamic_package = dynamic_package
  }
  if (search_type === 'undated') {
    query.search_type = search_type
    query.nights = nights || 1
    query.is_day_use = undefined
    query.checkin = undefined
    query.checkout = undefined
  } else {
    query.search_type = undefined
    query.nights = undefined
  }
  // eslint-disable-next-line camelcase
  if (isTiersMode && !Vue.prototype.$preview) {
    const { adults, kidsTiers, room_count } = searchForm
    query.adults = adults
    query.kids_tiers = JSON.stringify(shortenKidsTiers(kidsTiers))
    if (room_count) {
      query.room_count = room_count
    }
  } else if (Vue.prototype.$preview) {
    query = {
      ...query,
      rooms: JSON.stringify(searchForm.rooms)
    }
  } else {
    query = {
      ...query,
      rooms: JSON.stringify(searchForm.rooms)
    }
  }

  if (lottery_event_uuid) {
    query.lottery_event = lottery_event_uuid
  }

  if (lottery_access_token) {
    query.lottery_access_token = lottery_access_token
  }

  return query
}

export function genAPIParamsForRooms(searchForm = {}, isTiersMode, isSharedBedMode) {
  let searchParamsForAPI
  if (searchForm.is_day_use) {
    // Make sure that checkout date if day use is on
    const checkInDateParsed = parseDate(searchForm.checkin, 'YYYY/MM/DD')
    const checkOutDateParsed = checkInDateParsed.add(0, 'day')
    searchForm.checkout = formatDate02(checkOutDateParsed, 'YYYY/MM/DD', 'en')
  }
  if (isTiersMode && !Vue.prototype.$preview) {
    const { adults, order, mode, checkin, checkout } = searchForm
    searchParamsForAPI = {
      rooms: [
        {
          checkin_date: checkin,
          checkout_date: checkout,
          adults,
          kids_age_range: store.getters['search/getFormattedAges'],
          children: store.getters['search/getTotalKidsCount']
        }
      ],
      order,
      mode
    }
  } else if (Vue.prototype.$preview) {
    const { rooms, order, mode, checkin, checkout } = searchForm
    searchParamsForAPI = {
      order,
      rooms: rooms.map((room) => {
        const {
          kids_tiers: tierCodes,
          adult_companion,
          kid_tier_A_companion,
          adults,
          children,
          ...restRoom
        } = room

        const formattedRoom = {
          ...restRoom,
          adults: adults + (adult_companion || 0),
          adult_companion: adult_companion || 0,
          children: (children || 0) + (kid_tier_A_companion || 0),
          kid_tier_A_companion: kid_tier_A_companion || 0,
          checkin_date: checkin,
          checkout_date: checkout
        }
        if (tierCodes) {
          const tierCodeArray = [...tierCodes]
          if (kid_tier_A_companion > 0) {
            for (let c = 0; c < kid_tier_A_companion; c++) {
              tierCodeArray.push('A')
            }
          }
          formattedRoom.kids_age_range = transferTierCodeToTier(tierCodeArray || [])
        }
        return formattedRoom
      }),
      mode
    }
  } else {
    const { rooms, order, mode, checkin, checkout } = searchForm
    searchParamsForAPI = {
      order,
      rooms: rooms.map((room) => ({
        ...room,
        checkin_date: checkin,
        checkout_date: checkout,
        children: isSharedBedMode ? room.children ?? 0 : 0
      })),
      mode
    }
  }
  if (searchForm.filter?.length > 0) searchParamsForAPI.room_search_filters = searchForm.filter
  if (!_isNil(searchForm.is_day_use)) searchParamsForAPI.is_day_use = searchForm.is_day_use
  if (searchForm.search_type === 'undated') {
    searchParamsForAPI.search_type = searchForm.search_type
    searchParamsForAPI.rooms = searchParamsForAPI.rooms.map(
      ({ checkin_date, checkout_date, ...rest }) => rest
    )
    searchParamsForAPI.nights = searchForm.nights
    searchParamsForAPI.bookable = 'skip'
    delete searchParamsForAPI.is_day_use
  }
  if (searchForm.roomPlanCodes?.length > 0)
    searchParamsForAPI.room_plan_codes = searchForm.roomPlanCodes
  if (searchForm.roomTypeCodes?.length > 0)
    searchParamsForAPI.room_type_codes = searchForm.roomTypeCodes
  if (
    (searchForm.roomTypeCodes?.length > 0 || searchForm.hotelPlanCodes?.length > 0) &&
    searchForm.isFirstSearch &&
    searchForm.metasearchSource !== 'google_hotel_ads' &&
    !searchForm.defineCheckInOnUrl &&
    searchForm.search_type !== 'undated'
  ) {
    searchParamsForAPI.search_next_available_date_if_no_result = true
  }
  if (searchForm.hotelPlanCodes?.length > 0) {
    searchParamsForAPI.hotel_plan_codes = searchForm.hotelPlanCodes
  } else if (searchParamsForAPI.mode === 'secret') {
    searchParamsForAPI.mode = null
  }
  if (!_isNil(searchForm.is_including_occupied) && searchForm.mode !== 'voucher') {
    searchParamsForAPI.is_including_occupied = searchForm.is_including_occupied
  }
  if (!_isNil(searchForm.is_including_occupied)) {
    if (searchForm.is_including_occupied || searchForm.search_type === 'undated') {
      searchParamsForAPI.bookable = 'skip'
    } else if (searchForm.mode !== 'voucher') {
      searchParamsForAPI.bookable = true
    }
  }
  if (searchForm.limitedHotelPlanCodes?.length > 0) {
    if (searchParamsForAPI.hotel_plan_codes) {
      searchParamsForAPI.hotel_plan_codes = searchParamsForAPI.hotel_plan_codes.concat(
        searchForm.limitedHotelPlanCodes
      )
    } else {
      searchParamsForAPI.hotel_plan_codes = searchForm.limitedHotelPlanCodes
    }
  }
  if (searchForm.promo_code) searchParamsForAPI.promo_code = searchForm.promo_code
  if (searchForm.retry) searchParamsForAPI.retry = searchForm.retry
  if (searchForm.reservation_no) searchParamsForAPI.reservation_no = searchForm.reservation_no
  if (searchForm.coupon_code) searchParamsForAPI.coupon_code = searchForm.coupon_code
  if (searchForm.utm_source) searchParamsForAPI.utm_source = searchForm.utm_source
  if (searchForm.utm_medium) searchParamsForAPI.utm_medium = searchForm.utm_medium
  if (searchForm.utm_campaign) searchParamsForAPI.utm_campaign = searchForm.utm_campaign
  if (searchForm.campaignid) searchParamsForAPI.campaignid = searchForm.campaignid
  if (searchForm.adgroupid) searchParamsForAPI.adgroupid = searchForm.adgroupid
  if (searchForm.keyword) searchParamsForAPI.keyword = searchForm.keyword
  if (searchForm.gclid) searchParamsForAPI.gclid = searchForm.gclid
  if (searchForm.yclid) searchParamsForAPI.yclid = searchForm.yclid
  if (
    searchForm.metasearchSource &&
    searchForm.isFirstSearch &&
    store.getters['setting/getInitialSearch']
  ) {
    searchParamsForAPI.metasearch_source = searchForm.metasearchSource
  }

  // GHA info
  if (searchForm.isFirstSearch && store.getters['setting/getInitialSearch']) {
    if (searchForm.price_displayed_total)
      searchParamsForAPI.price_displayed_total = searchForm.price_displayed_total
    if (searchForm.google_site) searchParamsForAPI.google_site = searchForm.google_site
    if (searchForm.google_promo_code)
      searchParamsForAPI.google_promo_code = searchForm.google_promo_code
    if (searchForm.currency) searchParamsForAPI.currency = searchForm.currency
  }

  if (searchForm.isFirstSearch && store.getters['setting/getInitialSearch']) {
    store.dispatch('setting/setInitialSearch', false)
  }

  if (searchForm.type) {
    searchParamsForAPI.type = searchForm.type
  }

  if (searchForm.dynamic_package) {
    searchParamsForAPI.dynamic_package = searchForm.dynamic_package
  }

  if (searchForm.lottery_event_uuid) {
    searchParamsForAPI.lottery_event_uuid = searchForm.lottery_event_uuid
  }

  if (searchForm.lottery_access_token) {
    searchParamsForAPI.lottery_access_token = searchForm.lottery_access_token
  }

  if (searchForm.current_lottery_entry) {
    searchParamsForAPI.lottery_result_entry_number = searchForm.current_lottery_entry
  }

  if (store.getters['mcp/isMcpMode']) {
    searchParamsForAPI.mcp_currency = store.getters['mcp/selectedCurrency']
  }

  return searchParamsForAPI
}

export function genKidsAgesTransferPayload(searchForm) {
  return {
    rooms: [
      {
        checkin_date: searchForm.checkin,
        checkout_date: searchForm.checkout,
        adults: searchForm.adults,
        children: (searchForm.kidsAges || []).length,
        kids_age_range: searchForm.kidsAges
      }
    ]
  }
}

export function generateSearchForm(currentSearchForm) {
  const { code } = store.getters['setting/getSetting']
  const isTiersMode = store.getters['setting/isTiersMode']
  const defaultSearch = store.getters['setting/getDefautSearch']
  const initialSetting = store.getters['setting/getInit']
  // Generate default search form
  let defaultSearchFormData = {
    ...currentSearchForm,
    code,
    checkin: findDayAfterToday(defaultSearch.checkin_date_offset),
    checkout: findDayAfterToday(defaultSearch.checkin_date_offset + 1)
  }

  if (isTiersMode) {
    const kidsTiers = buildKidsTiers(null, initialSetting)
    defaultSearchFormData = {
      ...defaultSearchFormData,
      adults: defaultSearch.adults,
      kidsTiers,
      rooms: []
    }
  } else {
    defaultSearchFormData = {
      ...defaultSearchFormData,
      rooms: buildRooms(),
      kidsTiers: null
    }
  }
  return defaultSearchFormData
}
