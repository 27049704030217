import { setSearchCriteria } from 'utilities/searchCriteriaCookie'
import getServerUrl from 'api/domain'
import { logger } from 'src/errorHandler/logger'
import { sendDataLayerEvent } from 'utilities/analytics'
import store from 'store'
import router from 'routes'

const ROLLBAR_TIMEOUT = 3500

export const SIGN_IN_BUTTON_POSITION = {
  HEADER: 'header',
  PRICE_TABLE: 'price_table'
}

export const signInCreansmaerd = () => {
  const { hotelCode, creansmaerd, hotelId } = getCommonData()

  setSearchCriteria()
  const serverUrl = `${getServerUrl()}/hotels/${hotelCode}/creansmaerd/callback?otid=%OTID%`
  const signInUrl = `${
    creansmaerd.settings.login_host
  }/mypage/pc/login.php?rqu=${encodeURIComponent(serverUrl)}`

  redirect(hotelId, signInUrl)
}

export const signUpCreansmaerd = () => {
  const { hotelCode, creansmaerd, hotelId } = getCommonData()

  setSearchCriteria()
  const serverUrl = `${getServerUrl()}/hotels/${hotelCode}/creansmaerd/callback?otid=%OTID%`
  const signUpUrl = `${
    creansmaerd.settings.login_host
  }/mypage/pc/register_apply_input.php?rqu=${encodeURIComponent(serverUrl)}`

  redirect(hotelId, signUpUrl)
}

function redirect(hotelId, url) {
  const a = document.createElement('a')
  a.href = url
  a.innerHTML = hotelId
  document.body.appendChild(a)
  a.click()
}

export function registerSignInRollbar(additionalInfo = '') {
  const { hotelId } = getCommonData()
  const additionalText = additionalInfo ? `(${additionalInfo})` : ''
  const initialRouteName = router.currentRoute.name

  setTimeout(() => {
    const currentRouteName = router.currentRoute.name
    const isRouteChanged = initialRouteName !== currentRouteName

    if (!isRouteChanged) {
      logger.errorFromString(
        `Hotel ${hotelId} failed to redirect to the sign-in page. ${additionalText}`
      )
    }
  }, ROLLBAR_TIMEOUT)
}

export function sendSignInEvent({ buttonPosition, isSignUp = false }) {
  const { hotelId, brandId } = getCommonData()

  sendDataLayerEvent({
    event: isSignUp ? 'sign_up_redirect' : 'sign_in_redirect',
    brand_id: brandId,
    hotel_id: hotelId,
    button_position: buttonPosition
  })
}

function getCommonData() {
  const hotelId = store.getters['setting/getHotelId']
  const hotelCode = store.getters['setting/getHotelCode']
  const brandId = store.getters['setting/getBrandID']
  const creansmaerd = store.getters['setting/getCreansmaerd']

  return { hotelId, hotelCode, brandId, creansmaerd }
}
