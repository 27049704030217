import Membership from 'api/Membership'
import { sendDataToParentWindow } from 'utilities/domOperation'
import { removeUserToken, getTokenExtraData } from 'utilities/membership'
import Points from 'api/Points'
import i18n from '../../i18n'
import _keyBy from 'lodash/keyBy'
import _every from 'lodash/every'
import _compact from 'lodash/compact'
import { sendLogoutEvent } from 'utilities/analytics'
import router from 'src/routes'

const state = {
  loggedInUser: {
    organization: null
  },
  pointHistory: {
    data: [],
    currentPage: 1,
    programEnabled: false,
    totalPages: 0,
    totalRows: 0,
    total: 0,
    total_ranking_points: 0,
    program: {
      id: null,
      default: false,
      name: ''
    }
  },
  futurePoints: {
    data: [],
    currentPage: 1,
    programEnabled: false,
    totalPages: 0,
    totalRows: 0
  },
  loggedInToken: null,
  lineLiffToken: null,
  isLoginByBrandAuth: false,
  brandAuthToken: null,
  pointInfo: {},
  adultCompanion: {},
  kidCompanion: {},
  signedInProgram: {},
  nonSignedInPrograms: []
}

const mutations = {
  setLoggedInUser: (state, payload) => {
    state.loggedInUser = {
      ...state.loggedInUser,
      ...payload
    }
  },
  setOrganization: (state, payload) => {
    state.loggedInUser = {
      organization: payload
    }
  },
  clearLoggedInUser: (state) => {
    state.loggedInUser = { organization: null }
  },
  setPointHistory: (state, pointHistoryList) => {
    state.pointHistory.data = pointHistoryList
  },
  setFuturePoints: (state, futurePoints) => {
    state.futurePoints.data = futurePoints
  },
  addMorePointHistory: (state, pointHistoryList) => {
    state.pointHistory.data = [...state.pointHistory.data, ...pointHistoryList]
  },
  setPointHistoryMetaData: (state, { currentPage, totalPages, totalRows, programEnabled }) => {
    state.pointHistory.currentPage = currentPage
    state.pointHistory.totalPages = totalPages
    state.pointHistory.totalRows = totalRows
    state.pointHistory.programEnabled = programEnabled
  },
  setFuturePointsMetaData: (state, { currentPage, totalPages, totalRows, programEnabled }) => {
    state.futurePoints.currentPage = currentPage
    state.futurePoints.totalPages = totalPages
    state.futurePoints.totalRows = totalRows
    state.futurePoints.programEnabled = programEnabled
  },
  setTotalPoints: (state, total) => {
    state.pointHistory.total = total
  },
  setTotalRankingPoints: (state, value) => {
    state.pointHistory.total_ranking_points = value
  },
  setLoggedInToken: (state, token) => {
    state.loggedInToken = token
  },
  setLineLiffToken: (state, token) => {
    state.lineLiffToken = token
  },
  setIsLoginByBrandAuth: (state, payload) => {
    state.isLoginByBrandAuth = payload
  },
  setBrandAuthToken: (state, payload) => {
    state.brandAuthToken = payload
  },
  setPointInfo: (state, payload) => {
    state.pointInfo = payload
  },
  setAdultCompanion: (state, payload) => {
    state.adultCompanion = payload
    payload.translations_attributes = payload.translations_attributes.map((translation) => {
      return { ...translation, name: translation.name || payload.translations_attributes[0].name }
    })
    state.adultCompanion.translationMap = _keyBy(payload.translations_attributes || [], 'locale')
  },
  setKidCompanion: (state, payload) => {
    state.kidCompanion = payload
    payload.translations_attributes = payload.translations_attributes.map((translation) => {
      return { ...translation, name: translation.name || payload.translations_attributes[0].name }
    })
    state.kidCompanion.translationMap = _keyBy(payload.translations_attributes || [], 'locale')
  },
  setSignedInProgram: (state, payload) => {
    state.signedInProgram = payload
  },
  setNonSignedInPrograms: (state, payload) => {
    state.nonSignedInPrograms = _compact(payload)
  }
}

const getters = {
  getCurrentUser: (state) => state.loggedInUser,
  getUserName: (state) => state.loggedInUser.name,
  isSignedIn: (state) => !!(state.loggedInUser?.id ?? false) || !!state.loggedInUser?.organization,
  isSnsProvider: (state) => {
    return state.loggedInUser?.provider === 'facebook' || state.loggedInUser?.provider === 'line'
  },
  isCorporateSignedIn: (state) => !!state.loggedInUser?.organization,
  isOrganizationAdmin: (state) => (state.loggedInUser?.organization_role ?? '').includes('admin'),
  isUserSignedIn: (state) => !!state.loggedInUser?.id,
  isCreansmaerdUser: (state) => state.loggedInUser?.provider === 'creansmaerd',
  getRank: (state) => state.loggedInUser?.rank,
  getPointHistoryData: (state) => state.pointHistory?.data,
  getPointHistoryMetaData: (state) => {
    const { data, ...metaData } = state.pointHistory
    return metaData
  },
  getFuturePointsData: (state) => state.futurePoints.data,
  getFuturePointsMetaData: (state) => {
    const { data, ...metaData } = state.futurePoints
    return metaData
  },
  getPointBalance: (state) => {
    return state.pointHistory?.total || 0
  },
  getRankingPointBalance: (state) => {
    return state.pointHistory?.total_ranking_points || 0
  },
  getOrganization: (state) => {
    return state.loggedInUser?.organization
  },
  getOrganizationName: (state) => {
    return state.loggedInUser?.organization?.display_name || state.loggedInUser?.organization?.name
  },
  canUsePointProgram: (state, getters, rootState, rootGetters) =>
    (state.loggedInUser?.point_program_enabled && !rootGetters['setting/isPointsHidden']) ?? false,
  getLoggedInToken: (state) => state.loggedInToken,
  getLineLiffToken: (state) => state.lineLiffToken,
  isLoginByBrandAuth: (state) => state.isLoginByBrandAuth,
  brandAuthToken: (state) => state.brandAuthToken,
  getAdultCompanion: (state) => state.adultCompanion,
  getKidCompanion: (state) => state.kidCompanion,
  getProgramId: (state) => state.loggedInUser?.program_id,
  getMembershipId: (state) => state.loggedInUser?.membership_id,
  getSignedInProgram: (state) => state.signedInProgram,
  getNonSignedInPrograms: (state) => state.nonSignedInPrograms,
  getPointInfo: (state) => state.pointInfo,
  canUpdateInfo: (state, getters) => {
    const programs = [state.signedInProgram, ...state.nonSignedInPrograms]
    return (
      !!state.loggedInUser?.id &&
      !getters.isCreansmaerdUser &&
      _every(programs, 'update_member_profile_enabled')
    )
  }
}

const actions = {
  setLoggedInUser({ commit }, payload) {
    const firstName = payload.first_name
    const lastName = payload.last_name
    const firstNameKana = payload.first_name_kana
    const lastNameKana = payload.last_name_kana
    const formattedPayload = { ...payload }
    if (i18n.locale === 'ja' && (firstNameKana || lastNameKana)) {
      formattedPayload.name = `${lastNameKana} ${firstNameKana}`
    } else if (i18n.locale === 'en') {
      formattedPayload.name = firstName || lastName ? `${firstName} ${lastName}` : null
    } else {
      formattedPayload.name = firstName || lastName ? `${lastName} ${firstName}` : null
    }
    commit('setLoggedInUser', formattedPayload)
    const { isLoginByBrandAuth, brandAuthToken } = getTokenExtraData() || {}
    commit('setIsLoginByBrandAuth', isLoginByBrandAuth)
    commit('setBrandAuthToken', brandAuthToken)
  },
  logoutLocally({ commit, dispatch, state, rootGetters }, logoutOptions = {}) {
    const { skipChatbotRefresh = false } = logoutOptions
    const currentOrgId = state.loggedInUser?.organization?.id

    commit('clearLoggedInUser', {})
    commit('setSignedInProgram', {})
    commit('setNonSignedInPrograms', [])
    dispatch('booking/setGuest', null, { root: true })
    removeUserToken()
    const emptyCompanion = {
      translations_attributes: []
    }
    commit('setAdultCompanion', emptyCompanion)
    commit('setKidCompanion', emptyCompanion)
    commit('setPointHistory', [])
    commit('setPointHistoryMetaData', {
      currentPage: 1,
      programEnabled: false,
      totalPages: 0,
      totalRows: 0,
      total: 0
    })
    commit('setFuturePoints', [])
    commit('setFuturePointsMetaData', {
      currentPage: 1,
      programEnabled: false,
      totalPages: 0,
      totalRows: 0,
      total: 0
    })
    // Notify parent window (search bar)
    sendDataToParentWindow({
      action: 'login',
      name: null,
      isInCBIframe: rootGetters['setting/isInChatbotIframe'],
      currentOrgId,
      skipChatbotRefresh
    })

    sendDataToParentWindow({
      action: 'receiveCompanionInfo',
      payload: null
    })

    if (logoutOptions.targetRoute) {
      router.push(logoutOptions.targetRoute)
    }
  },
  logout({ dispatch }, logoutOptions = {}) {
    return Membership.logout().finally(() => {
      dispatch('logoutLocally', logoutOptions)
      sendLogoutEvent()
    })
  },
  fetchPointHistory({ commit }, payload = {}) {
    const { currentPage = 1, itemsPerPage = 5, isLoadMore = false } = payload
    return Points.getHistory({ page: currentPage, per_page: itemsPerPage, scope: 'current' })
      .then((response) => {
        if (isLoadMore) {
          commit('addMorePointHistory', response.data)
        } else {
          commit('setPointHistory', response.data)
        }
        commit('setPointHistoryMetaData', {
          currentPage: Number(response.page),
          totalPages: Number(response.total_pages),
          totalRows: Number(response.total_entries),
          programEnabled: response.program_enabled
        })
      })
      .catch((err) => {
        throw err
      })
  },
  fetchFuturePoints({ commit }, payload = {}) {
    const { currentPage = 1, itemsPerPage = 5 } = payload
    return Points.getHistory({ page: currentPage, per_page: itemsPerPage, scope: 'future' })
      .then((response) => {
        commit('setFuturePoints', response.data)
        commit('setFuturePointsMetaData', {
          currentPage: Number(response.page),
          totalPages: Number(response.total_pages),
          totalRows: Number(response.total_entries),
          programEnabled: response.program_enabled
        })
      })
      .catch((err) => {
        throw err
      })
  },
  fetchCurrentPoint({ commit }) {
    return Points.get()
      .then((response) => {
        commit('setTotalPoints', response.data.total_point)
        commit('setTotalRankingPoints', response.data.ranking_points_value)
        commit('setPointInfo', response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  },
  setCurrentOrganization({ commit }, organization) {
    commit('setOrganization', organization)
  },
  setLoggedInToken({ commit }, token) {
    commit('setLoggedInToken', token)
  },
  setLineLiffToken({ commit }, token) {
    commit('setLineLiffToken', token)
  },
  setIsLoginByBrandAuth({ commit }, isLoginByBrandAuth) {
    commit('setIsLoginByBrandAuth', isLoginByBrandAuth)
  },
  setBrandAuthToken({ commit }, brandAuthToken) {
    commit('setBrandAuthToken', brandAuthToken)
  },
  setCompanionInfo({ commit }, companionInfoList) {
    companionInfoList.forEach((tierAttr) => {
      if (tierAttr.code === 'adult_companion') {
        commit('setAdultCompanion', tierAttr)
      } else if (tierAttr.code === 'kid_tier_A_companion') {
        commit('setKidCompanion', tierAttr)
      }
    })
  },
  setSignedInProgram: ({ commit }, payload) => {
    commit('setSignedInProgram', payload)
  },
  setNonSignedInPrograms: ({ commit }, payload) => {
    commit('setNonSignedInPrograms', payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
