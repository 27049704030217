<template>
  <div />
</template>

<script>
import Rooms from 'api/Rooms'
import { mapGetters } from 'vuex'
import { genAPIParamsForRooms } from 'utilities/generateSearchForm'
import assignQueryToSearchFormMixin from 'mixins/assignQueryToSearchFormMixin'

export default {
  name: 'RoomsPreloader',
  mixins: [assignQueryToSearchFormMixin],

  computed: {
    ...mapGetters({
      isTiersMode: 'setting/isTiersMode',
      isInitAPIArrived: 'search/isInitAPIArrived',
      isSharedBedKidsMode: 'setting/showSharedBedKids',
      isMcpEnabled: 'mcp/isMcpEnabled'
    })
  },

  async created() {
    if (this.isInitAPIArrived) {
      return
    }

    const searchFormData = await this.assignQueryToSearchForm().catch(() => {})
    const params = genAPIParamsForRooms(searchFormData, this.isTiersMode, this.isSharedBedKidsMode)

    if (this.$route.query?.type) {
      params.type = this.$route.query?.type
    }

    const res = await Rooms.get(params).catch(() => {})

    if (this.isMcpEnabled && res?.mcp_currency && !this.$route.query.current_reservation) {
      this.$store.dispatch('mcp/setSelectedCurrency', res.mcp_currency)
    }

    if (res?.rooms && this.$route.query?.type === 'room') {
      this.$store.dispatch('search/setPreloadRoomsResult', res)
    } else if (res?.plans && this.$route.query?.type === 'plan') {
      this.$store.dispatch('search/setPreloadPlansResult', res)
    }
  }
}
</script>
