import { mapMutations, mapGetters } from 'vuex'
import triplaLocalStorage from 'utilities/triplaLocalStorage'
import EventBus from 'utilities/EventBus'
import { localeMapping } from 'locales/utilities'
import _values from 'lodash/values'

export default {
  data() {
    return {
      reloadKey: 0
    }
  },

  created() {
    EventBus.$on('reloadView', () => {
      this.reloadKey++
    })
  },

  computed: {
    ...mapGetters({
      getBookLanguages: 'setting/getBookLanguages'
    })
  },

  methods: {
    ...mapMutations('search', ['setRooms', 'setPlans']),
    changeLocale(value) {
      const bookLanguages = this.getBookLanguages
      const locales = bookLanguages.length > 0 ? bookLanguages : _values(localeMapping)
      const locale = locales.find((l) => value === l) || locales[0]

      this.$i18n.locale = locale
      triplaLocalStorage.setItem('triplabot_locale', locale)
      this.reloadKey++
      this.setRooms(null)
      this.setPlans(null)
      if (this.$route.query.locale) {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            ...this.$route.query,
            locale: undefined
          }
        })
      }
    }
  }
}
